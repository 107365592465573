<template>
  <div class="box">
    <el-breadcrumb class="m_tb_10" separator-class="el-icon-arrow-right">
      <template v-if="type === '1'">
        <el-breadcrumb-item :to="{ name: 'practiceAssistant' }">
          学生实践助手
        </el-breadcrumb-item>
        <el-breadcrumb-item
          :to="{
            name: 'practiceAssistant',
            query: { type: 'course' },
            params: { current, selected },
          }"
          v-show="childType == 'course'"
        >
          精品微课
        </el-breadcrumb-item>
        <el-breadcrumb-item
          :to="{
            name: 'practiceAssistant',
            query: { type: 'labour' },
            params: { current, selected },
          }"
          v-show="childType == 'labour'"
        >
          劳动周
        </el-breadcrumb-item>
      </template>
      <template v-else>
        <el-breadcrumb-item :to="{ name: 'teachersOnly' }">
          教师资源专享
        </el-breadcrumb-item>
        <el-breadcrumb-item
          :to="{
            name: 'teachersOnly',
            query: { type: 'resource' },
            params: { current },
          }"
          v-show="childType == 'resource'"
        >
          微课资源
        </el-breadcrumb-item>
        <el-breadcrumb-item
          :to="{
            name: 'teachersOnly',
            query: { type: 'screenRecording' },
            params: { current },
          }"
          v-show="childType == 'screenRecording'"
        >
          课堂实录
        </el-breadcrumb-item>
        <el-breadcrumb-item
            :to="{
            name: 'teachersOnly',
            query: { type: 'techerJN' },
            params: { current },
          }"
            v-show="childType == 'techerJN'"
        >
          教师技能大赛
        </el-breadcrumb-item>

      </template>
      <el-breadcrumb-item>{{ courseData.name }}</el-breadcrumb-item>
    </el-breadcrumb>
    <video-player
      class="video-player vjs-custom-skin m_b_20"
      ref="videoPlayer"
      :playsinline="playsinline"
      :options="playerOptions"
      v-if="courseData.type == 3"
    />
    <img
      class="preview-img"
      :src="courseData.resourcesUrl"
      alt=""
      v-else-if="courseData.type == 4"
    />
    <div class="course flex_row bg_fff br_5 m_tb_20" >
      <div class="course-img-box m_r_30 br_5">
        <img class="course-img" :src="courseData.coverUrl" alt="" />
        <i class="el-icon-video-play play-icon"></i>
      </div>
      <div class="info-box p_in_20">
        <div class="fs_18 m_tb_5">
          <span class="m_r_30">{{ courseData.name }}</span>
          <span>
            <i
              class="el-icon-star-off fs_20 m_r_20 pointer"
              @click.stop="collect_click(courseData)"
              v-if="courseData.collectId === -1"
            ></i>
            <i
              class="el-icon-star-on fs_20 m_r_20 pointer"
              @click.stop="collect_click(courseData)"
              style="color: #ff8540"
              v-else
            ></i>
            <img
              class="like pointer"
              :src="like1"
              alt=""
              @click="like_click(courseData)"
              v-if="courseData.likeId === -1"
            />
            <img
              class="like pointer"
              :src="like2"
              alt=""
              @click="like_click(courseData)"
              v-else
            />
          </span>
        </div>
        <div class="fs_14 m_tb_5" v-show="courseData.info.teacher">
          <span class="c_999">课程讲师：</span>
          <span class="c_666">{{ courseData.info.teacher }}</span>
        </div>
        <div class="fs_14 m_tb_5">
          <span class="c_999">点赞人数：</span>
          <span class="c_666">{{ courseData.likeCount }}人</span>
        </div>
        <div class="fs_14 m_tb_5">
          <span class="c_999">收藏人数：</span>
          <span class="c_666">{{ courseData.collectCount }}人</span>
        </div>
        <div class="fs_14 m_tb_5">
          <span class="c_999">浏览次数：</span>
          <span class="c_666">{{ courseData.browseNumber }}人</span>
        </div>
        <button
          v-show="courseData.type == 1"
          class="preview-btn br_5 c_fff fs_16 m_tb_20 pointer"
          @click="preview_click"
        >
          预览
        </button>
      </div>

<!--      <div-->
<!--          class="bg_fff p_in_20 resource_head"-->
<!--          style="width: 30%; box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);"-->
<!--          v-if="enclosureList.length !== 0">-->
<!--        <div class="fs_20 fw_bold">资源附件</div>-->
<!--        <ResourceEnclosure-->
<!--            v-for="enclosureInfo of enclosureList"-->
<!--            :key="enclosureInfo.id"-->
<!--            :enclosureInfo="enclosureInfo" />-->
<!--      </div>-->

      <div class="resource-list-box m_l_20 br_5 bg_fff">
        <div class="fs_20 fw_bold m_in_10">资源列表</div>
        <div
            class="fs_16 m_tb_10 pointer p_in_10"
            :class="{ 'selected-resource': data.id == courseData.id }"
            v-for="data in enclosureList"
            :key="data.id"
            @click="resource_click(data)"
            >
          {{ data.name }}
          <el-tag
              class="m_r_30"
              style="margin-top: 8px"
              size="mini">
            {{ data.types }}
          </el-tag>
        </div>
      </div>

    </div>

  </div>

</template>

<script>
import course from "assets/image/Group 651.png";
import like1 from "assets/image/likes1.png";
import like2 from "assets/image/likes2.png";
import { getResourceDetail } from "@/api/resourceCenter/region";
import { collectResource, likeResource } from "@/api/resourceCenter/school";
import { htmlUnEscape } from "utils/utils";
import ResourceEnclosure from '@/views/resourceCenter/components/region/ResourceEnclosure';

export default {
  name: "Detail",
  components: {
    ResourceEnclosure,
  },
  data() {
    return {
      course,
      like1,
      like2,
      // 视频播放
      playsinline: true,
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        controls: true, //是否显示控制条
        autoplay: false, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4",
            src: "", // url地址
          },
        ],
        poster: "", // 封面地址
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          // timeDivider: true, // 当前时间和持续时间的分隔符
          // durationDisplay: true, // 显示持续时间
          // remainingTimeDisplay: false, // 是否显示剩余时间功能
          // fullscreenToggle: true // 是否显示全屏按钮
          volumeControl: true,
        },
      },
      courseData: { info: { teacher: "" } },
      type: "1",
      current: null,
      selected: null,
      childType: null,
      enclosureList: [],
    };
  },
  mounted() {
    let query = this.$route.query;
    let id = query.id;
    this.current = query.current;
    this.selected = query.selected;
    this.type = query.type;
    this.childType = query.childType;
    this.getCourseDetail(id);
  },
  methods: {
    getCourseDetail(id) {
      getResourceDetail({ id }).then((res) => {
        this.courseData = res.data;
        this.courseData.info = res.data.introduce.split("&&")[1]
          ? JSON.parse(htmlUnEscape(res.data.introduce.split("&&")[1]))
          : {};
        this.courseData.introduce = res.data.introduce.split("&&")[0];
        if (res.data.type == 3)
          this.playerOptions.sources[0].src = res.data.resourcesUrl;

        this.enclosureList = this.courseData.enclosureList;
      });
    },
    collect_click(data) {
      collectResource({
        id: data.id,
        type: data.collectId === -1 ? 1 : 0,
      }).then((res) => {
        if (data.collectId === -1) {
          if (res.code === 200) {
            this.courseData.collectId = 1;
            this.$message.success("收藏成功！");
            this.courseData.collectCount += 1;
          } else {
            this.$message.success("收藏失败，请稍后重试！");
          }
        } else {
          if (res.code === 200) {
            this.courseData.collectId = -1;
            this.$message.success("取消收藏成功！");
            this.courseData.collectCount -= 1;
          } else {
            this.$message.warning("取消收藏失败，请稍后重试！");
          }
        }
      });
    },
    resource_click(data) {
      //.getCourseDetail(data.id);
      this.courseData = data;
    },
    like_click(data) {
      likeResource({ id: data.id, type: data.likeId === -1 ? 1 : 0 }).then(
        (res) => {
          if (data.likeId === -1) {
            if (res.code === 200) {
              this.courseData.likeId = 1;
              this.$message.success("点赞成功！");
              this.courseData.likeCount += 1;
            } else {
              this.$message.success("点赞失败，请稍后重试！");
            }
          } else {
            if (res.code === 200) {
              this.courseData.likeId = -1;
              this.$message.success("取消点赞成功！");
              this.courseData.likeCount -= 1;
            } else {
              this.$message.warning("取消点赞失败，请稍后重试！");
            }
          }
        }
      );
    },
    preview_click() {
      window.open(
        "http://139.155.146.142:8012/onlinePreview?url=" +
          encodeURIComponent(btoa(this.courseData.resourcesUrl))
      );
    },
  },
};
</script>

<style scoped>
.box {
  width: 1200px;
  margin: 0 auto;
}

.like {
  vertical-align: text-bottom;
  width: 22px;
}

.input-box > button {
  width: 100px;
  height: 32px;
  background: linear-gradient(180deg, #64f4cf, #12c294 100%);
  border-radius: 4px;
  border: 0;
  color: #fff;
  font-size: 16px;
  margin-top: 10px;
}

.course-img-box {
  position: relative;
  max-width: 400px;
  max-height: 250px;
  overflow: hidden;
}

.play-icon {
  font-size: 50px;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.course-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.preview-btn {
  width: 120px;
  background-color: #12c294;
  border: 0;
  padding: 5px 10px;
}

.preview-img {
  width: 100%;
}
</style>
