import { render, staticRenderFns } from "./ResourceEnclosure.vue?vue&type=template&id=096540d6&scoped=true"
import script from "./ResourceEnclosure.vue?vue&type=script&lang=js"
export * from "./ResourceEnclosure.vue?vue&type=script&lang=js"
import style0 from "./ResourceEnclosure.vue?vue&type=style&index=0&id=096540d6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "096540d6",
  null
  
)

export default component.exports