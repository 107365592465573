<template>
  <div class="flex_col pointer resource-box br_10" @click="toDetails(enclosureInfo.id)">
    <img class="img" :src="enclosureInfo.coverUrl?enclosureInfo.coverUrl:typeJSON[enclosureInfo.types]" alt="">
    <div>
      <div class="text_hidden fs_14 fw_bold p_lr_5 m_tb_5">{{ enclosureInfo.name }}</div>
    </div>
    <div class="tag c_fff fs_12">{{ enclosureInfo.types }}</div>
  </div>
</template>
<script>
import word from 'assets/image/word.png'
import excel from 'assets/image/excel.png'
import ppt from 'assets/image/ppt.png'
import pdf from 'assets/image/pdf.png'
import video from 'assets/image/video.png'
import voice from 'assets/image/voice.png'

export default {
  name: 'ResourceEnclosure',
  props: {
    enclosureInfo: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  data: () => {
    return {
      typeJSON: {
        doc: word,
        pdf: pdf,
        pptx: ppt,
        xlsx: excel,
        mp4: video,
        m3u8: video,
        mp3: voice
      }
    }
  },
  methods: {
    toDetails(id) {
      // if (this.scene === 'school') return this.$router.push({path: '/resource/details', query: {scene: this.scene, id}})
      this.$router.push({path: '/resource/details', query: {id}})
    }
  }
}
</script>
<style scoped>
.img {
  width: 100%;
  max-height: 100px;
  object-fit: cover;
}

.resource-box {
  width: 170px;
  overflow: hidden;
  position: relative;
}

.tag {
  width: 50px;
  height: 20px;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 0 10px 0 10px;
  position: absolute;
  right: 0;
  text-align: center;
  line-height: 20px;
}
</style>
